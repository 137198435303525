import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';

function App () {
  const [loaded, setLoaded] = useState(false)
  const [display, setDisplay] = useState('3d')
  const [stage, setStage] = useState('981dcc5d-a3b1-4554-afbc-b27e8edfcbb7')
  // webgl - 981dcc5d-a3b1-4554-afbc-b27e8edfcbb7
  // vp - c2fbe9e7-a6d9-42a7-b7f5-e490e07b4226

  const unloadPlayer = async () =>{
    if(display === '3d'){
      await window.player.unload();
      setDisplay('image')
      console.log('u did it');
      setLoaded(false)
    }else{
      await window.player.unload();
      setDisplay('3d')
      console.log('u did it');
      setLoaded(false)
    }
    
  }

  useEffect(() => {
    window
      .threekitPlayer({
        authToken: '09881489-2d2b-485b-9897-34f06b56d4cd',
        el: document.getElementById('player'),
        stageId:
          display === 'image'
            ? 'c2fbe9e7-a6d9-42a7-b7f5-e490e07b4226'
            : '981dcc5d-a3b1-4554-afbc-b27e8edfcbb7',
        assetId: '9b730242-b4a4-4dff-b9f2-f2e2c51c2e9e',
        display,
        showLoadingThumbnail: true,
        showConfigurator: true,
        initialConfiguration:
          display === 'image' ? { bgPlate: 'none' } : undefined,
        showAR: true
      })
      .then(async function (player) {
        window.player = player
        await player.when('loaded');
        setLoaded(true)

      })
  }, [display])
  return (
    <div className='App'>
      <div
        id='player'
        style={{ height: '500px', width: display === 'image' ? isMobile ? '100vw' : '800px' : '90vw', margin: 'auto' }}
      ></div>
      <div style={{ width: '90vw' }}>
        {loaded ? (
          <center>
            <img src='./rotate.png' style={{cursor: 'pointer'}} onClick={() => unloadPlayer()}/>
          </center>
        ) : null}
      </div>
    </div>
  )
}

export default App
